import { useEffect } from "react";
import { UseQueryFunctionResult } from "@lookiero/messaging-react";
import { Segment } from "@lookiero/sty-psp-segment";
import { QuizProjection } from "../../../projection/quiz/quiz";
import { GoneQuiz } from "../../projection/quiz/GoneQuiz";
import { useViewQuizById } from "../../projection/quiz/react/useViewQuizById";
import { QuizId, useQuizIdForSegment } from "./useQuizIdForSegment";

interface UseViewQuizForSegmentFunctionReturn {
  readonly id: QuizId;
  readonly quiz: UseQueryFunctionResult<QuizProjection>;
}

interface UseViewQuizForSegmentFunctionArgs {
  readonly segment: Segment;
}

interface UseViewQuizForSegmentFunction {
  (args: UseViewQuizForSegmentFunctionArgs): UseViewQuizForSegmentFunctionReturn;
}

const useViewQuizForSegment: UseViewQuizForSegmentFunction = ({ segment }) => {
  const [quizId, createQuizId] = useQuizIdForSegment({ segment });
  const [quiz, quizStatus] = useViewQuizById({ id: quizId });

  useEffect(() => {
    if (quiz === GoneQuiz) {
      createQuizId();
    }
  }, [createQuizId, quiz]);

  /**
   * Quiz may be null (error response)
   * But in order for the later integration to be easier
   * we are doing this type assertion.
   */
  return {
    id: quizId,
    quiz: [quiz === GoneQuiz ? undefined : (quiz as QuizProjection), quizStatus],
  };
};

export { useViewQuizForSegment };
