import React, { FC, useCallback, useEffect, useRef } from "react";
import { generatePath, useLocation, useMatch, useNavigate } from "react-router-native";
import { Spinner } from "@lookiero/aurora";
import {
  QuestionProjection,
  ScreenProjection,
  filteredQuestionsByAnswers,
  isChildQuestion,
  nextQuestion,
  questionById,
  questionIsHostScreen,
  useViewValidation,
} from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { MESSAGING_CONTEXT_ID } from "../../delivery/baseBootstrap";
import { useDefinition } from "../hooks/useDefinition";
import { useStaticInfo } from "../hooks/useStaticInfo";
import { useViewQuizForSegment } from "../hooks/useViewQuizForSegment";
import { Routes } from "./routes";

interface ScreenQuestionByQuestionIdFunctionArgs {
  readonly questions: QuestionProjection[];
  readonly questionId: string;
}
interface ScreenQuestionByQuestionIdFunction {
  (args: ScreenQuestionByQuestionIdFunctionArgs): ScreenProjection | undefined;
}
const screenQuestionByQuestionId: ScreenQuestionByQuestionIdFunction = ({ questions, questionId }) =>
  questions.find((question) => isChildQuestion({ questionId, question })) as ScreenProjection;

interface NavigateToInitialQuestionFunctionArgs {
  readonly slug: string;
  readonly validationIssues?: boolean;
}
interface NavigateToInitialQuestionFunction {
  (args: NavigateToInitialQuestionFunctionArgs): void;
}

interface QuizMiddlewareProps {
  readonly loader?: JSX.Element;
  readonly children: JSX.Element;
}

const QuizMiddleware: FC<QuizMiddlewareProps> = ({ loader = <Spinner />, children }) => {
  const { state } = useLocation();
  const stateRef = useRef(state);
  stateRef.current = state;
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  navigateRef.current = navigate;

  const { basePath, segment } = useStaticInfo();

  const hasNavigatedToNextQuestion = useRef(false);

  const {
    definition: [definition],
  } = useDefinition();
  const {
    quiz: [quiz],
  } = useViewQuizForSegment({ segment });

  const shouldValidate = Boolean(quiz?.answers);
  const [validation] = useViewValidation({
    contextId: MESSAGING_CONTEXT_ID,
    answers: quiz?.answers,
    questions: definition,
  });

  const questionRouteMatch = useMatch(`${basePath}/${Routes.QUESTION}`);
  const questionShown = useRef(false);
  questionShown.current = questionShown.current || Boolean(questionRouteMatch);

  const navigateToInitialQuestion: NavigateToInitialQuestionFunction = useCallback(
    ({ slug, validationIssues }) => {
      navigateRef.current(generatePath(`${basePath}/${Routes.QUESTION}`, { segment: segment.toLowerCase(), slug }), {
        state: { ...stateRef.current, validationIssues },
      });
      hasNavigatedToNextQuestion.current = true;
    },
    [basePath, segment],
  );

  useEffect(() => {
    if (!definition || quiz === undefined || (shouldValidate && validation === undefined)) {
      return;
    }

    // Initial navigation done
    if (hasNavigatedToNextQuestion.current) {
      return;
    }

    // Empty quiz => navigate to first screen
    if (quiz === null || Object.keys(quiz.answers).length === 0) {
      const firstQuestion = definition.find(questionIsHostScreen);

      if (!firstQuestion) {
        return;
      }

      navigateToInitialQuestion({ slug: firstQuestion.metadata.slug });
      return;
    }

    const filteredQuestions = filteredQuestionsByAnswers({ questions: definition, answers: quiz.answers });
    const questionsWithAnswers = filteredQuestions.filter((question) =>
      Object.keys(quiz.answers).some((answer) =>
        isChildQuestion({
          questionId: answer,
          question,
        }),
      ),
    );

    if (questionsWithAnswers.length === 0) {
      return;
    }

    const lastAnsweredQuestion = questionsWithAnswers[questionsWithAnswers.length - 1];

    if (!lastAnsweredQuestion || !questionIsHostScreen(lastAnsweredQuestion)) {
      return;
    }

    // Navigate to the first question with validation issues
    // if lastAnsweredQuestion is posterior to firstScreenQuestionWithValidationIssues
    const firstScreenQuestionWithValidationIssues =
      validation && validation[0]
        ? screenQuestionByQuestionId({
            questions: definition,
            questionId: (
              questionById({ questionId: validation[0].questionId, questions: definition }) as QuestionProjection
            ).id,
          })
        : null;

    const firstScreenQuestionWithValidationIssuesIndex = definition.findIndex(
      (question) => question.id === firstScreenQuestionWithValidationIssues?.id,
    );
    const lastAnsweredNextQuestionIndex = definition.findIndex(
      (question) => question.id === nextQuestion({ question: lastAnsweredQuestion, questions: filteredQuestions })?.id,
    );

    const nextQuestionIndex =
      firstScreenQuestionWithValidationIssuesIndex !== -1 &&
      lastAnsweredNextQuestionIndex > firstScreenQuestionWithValidationIssuesIndex
        ? firstScreenQuestionWithValidationIssuesIndex
        : lastAnsweredNextQuestionIndex;

    const nextQuestionSlug =
      nextQuestionIndex !== -1
        ? (definition[nextQuestionIndex] as ScreenProjection).metadata.slug
        : lastAnsweredQuestion.metadata.slug;

    if (!nextQuestionSlug) {
      return;
    }

    const hasValidationIssues =
      nextQuestionIndex === firstScreenQuestionWithValidationIssuesIndex &&
      nextQuestionIndex !== lastAnsweredNextQuestionIndex;

    navigateToInitialQuestion({ slug: nextQuestionSlug, validationIssues: hasValidationIssues });
  }, [definition, navigateToInitialQuestion, quiz, shouldValidate, validation]);

  if (!definition) {
    return loader;
  }

  return children;
};

export { QuizMiddleware };
