import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo } from "react";
import { generatePath, Navigate, useLocation, useMatch, useParams } from "react-router-native";
import invariant from "tiny-invariant";
import { Spinner } from "@lookiero/aurora";
import { Segment } from "@lookiero/sty-psp-segment";
import { useStaticInfo } from "../hooks/useStaticInfo";
import { useViewQuizForSegment } from "../hooks/useViewQuizForSegment";
import { LocationState } from "./LocationState";
import { Routes } from "./routes";

interface Location {
  readonly state: LocationState | null;
}

interface SegmentMiddlewareApi {
  readonly requiresSegmentQuestion: () => boolean;
}

const SegmentMiddlewareContext = createContext<SegmentMiddlewareApi>(null as unknown as SegmentMiddlewareApi);

interface SegmentMiddlewareProps {
  readonly children: ReactNode;
}

const SegmentMiddleware: FC<SegmentMiddlewareProps> = ({ children }) => {
  const { state }: Location = useLocation();
  const { segment: segmentParam } = useParams();
  const { basePath, selectSegment } = useStaticInfo();

  const {
    quiz: [womenQuiz],
  } = useViewQuizForSegment({ segment: Segment.WOMEN });
  const {
    quiz: [menQuiz],
  } = useViewQuizForSegment({ segment: Segment.MEN });

  const segment: Segment = segmentParam?.toUpperCase() as Segment;
  const segmentRouteMatch = useMatch(`${basePath}/${Routes.SEGMENT}/*`);

  // Sync URL segment
  useEffect(() => {
    if (segmentParam) {
      selectSegment({ segment: segmentParam.toUpperCase() as Segment });
    }
  }, [segment, segmentParam, selectSegment]);

  // THIS SHOULD BE REMOVED WHEN LAUNCHED
  const womenSegmentRouteMatch = useMatch(`${basePath}/${Segment.WOMEN.toLowerCase()}/*`);

  // THIS SHOULD BE UNCOMMENTED WHEN LAUNCHED
  // const requiresSegmentQuestion = useCallback(() => {
  //   return segment === Segment.MEN;
  // }, [segment]);
  const requiresSegmentQuestion = useCallback(() => false, []);

  const value = useMemo(() => ({ requiresSegmentQuestion }), [requiresSegmentQuestion]);

  if (womenQuiz === undefined || menQuiz === undefined) {
    return <Spinner />;
  }

  // THIS SHOULD BE REMOVED WHEN LAUNCHED
  if (!womenSegmentRouteMatch) {
    return (
      <Navigate
        state={state}
        to={generatePath(`${basePath}/${Routes.SEGMENT}`, { segment: Segment.WOMEN.toLowerCase() })}
      />
    );
  }

  if (!segmentRouteMatch && !state?.backFromQuiz) {
    if (
      // There is no existing Quiz OR has started a Quiz in each Segment
      (womenQuiz === null && menQuiz === null) ||
      (womenQuiz && menQuiz)
    ) {
      return <Navigate state={state} to={generatePath(`${basePath}/${Routes.SEGMENT_QUESTION}`)} />;
    }

    // There is an existing Quiz (specific / single Segment)
    if ((womenQuiz?.segment || menQuiz?.segment) && !segment) {
      const quizSegment = (womenQuiz?.segment || menQuiz?.segment) as Segment;

      return (
        <Navigate
          state={state}
          to={generatePath(`${basePath}/${Routes.SEGMENT}`, { segment: quizSegment.toLowerCase() })}
        />
      );
    }
  }

  return <SegmentMiddlewareContext.Provider value={value}>{children}</SegmentMiddlewareContext.Provider>;
};

const useSegmentMiddleware = () => {
  const segmentContext = useContext(SegmentMiddlewareContext);

  invariant(
    segmentContext,
    "Your are trying to use the useSegmentMiddleware hook without wrapping your app with the <SegmentMiddleware>.",
  );

  return segmentContext;
};

export { SegmentMiddleware, useSegmentMiddleware };
