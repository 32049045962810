import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorBgBase, borderRadius3, space2 } = theme();

const style = StyleSheet.create({
  image: {
    backgroundColor: colorBgBase,
    borderRadius: borderRadius3,
    flex: 1,
    overflow: "hidden",
  },
  imageContainer: {
    marginVertical: space2,
  },
});

export { style };
