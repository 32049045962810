import React, { FC, useCallback } from "react";
import { Image } from "react-native";
import { useLocation, useNavigate } from "react-router-native";
import { Button, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { Body } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { AspectRatioView } from "@lookiero/sty-psp-ui";
import { Layout } from "../../../layout/Layout";
import { TrackingPage } from "../../../tracking/tracking";
import { useTrackPageView } from "../../../tracking/useTrackPageView";
import { useStaticInfo } from "../../hooks/useStaticInfo";
import { useViewQuizForSegment } from "../../hooks/useViewQuizForSegment";
import { I18nMessages } from "../../i18n/i18n";
import { Routes } from "../../routing/routes";
import { style } from "./NotFound.style";

interface NotFoundProps {
  readonly layout: Layout;
}
const NotFound: FC<NotFoundProps> = ({ layout: Layout }) => {
  const descriptionText = useI18nMessage({ id: I18nMessages.NOT_FOUND_DESCRIPTION });
  const buttonText = useI18nMessage({ id: I18nMessages.NOT_FOUND_BUTTON });

  const { basePath, country, segment } = useStaticInfo();
  const { id } = useViewQuizForSegment({ segment });
  useTrackPageView({
    country,
    segment,
    page: TrackingPage.NOT_FOUND,
    quizId: id,
  });

  const { state } = useLocation();
  const navigate = useNavigate();
  const handleOnPress = useCallback(
    () => navigate(`${basePath}/${Routes.HOME}`, { state }),
    [basePath, navigate, state],
  );

  return (
    <Layout stepper={<></>} style={{ scrollView: style.container }}>
      <Body style={{ column: style.bodyColumn }}>
        <AspectRatioView aspectRatio={1.25}>
          <Image
            resizeMode="contain"
            source={require("../../../../../public/images/not-found.png")}
            style={style.image}
            testID="not-found-image"
          />
        </AspectRatioView>

        <Text level={1} style={style.description}>
          {descriptionText}
        </Text>

        <Button onPress={handleOnPress}>{buttonText}</Button>
      </Body>
    </Layout>
  );
};

export { NotFound };
