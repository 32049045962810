import { useCallback, useEffect } from "react";
import { v4 as uuid } from "uuid";
import { QueryStatus } from "@lookiero/messaging-react";
import { useLogger } from "@lookiero/sty-psp-logging";
import { Segment } from "@lookiero/sty-psp-segment";
import { useUpdateUiSetting, useViewUiSettingByKey } from "@lookiero/sty-psp-ui-settings";
import { QUIZ_UI_SETTING_ID } from "../../../domain/quiz/model/quizId";
import { MESSAGING_CONTEXT_ID } from "../../delivery/baseBootstrap";

type QuizId = string | undefined;

type UseQuizIdForSegmentResult = [quizId: QuizId, createQuizId: () => void];

interface UseQuizIdForSegmentFunctionArgs {
  readonly segment: Segment;
}

interface UseQuizIdForSegmentFunction {
  (args: UseQuizIdForSegmentFunctionArgs): UseQuizIdForSegmentResult;
}
const useQuizIdForSegment: UseQuizIdForSegmentFunction = ({ segment }) => {
  const logger = useLogger();
  const [uiSetting, uiSettingStatus] = useViewUiSettingByKey({
    contextId: MESSAGING_CONTEXT_ID,
    key: QUIZ_UI_SETTING_ID[segment],
  });
  const [updateUiSetting] = useUpdateUiSetting({ contextId: MESSAGING_CONTEXT_ID, logger });

  const createQuizId = useCallback(() => {
    updateUiSetting({
      aggregateId: QUIZ_UI_SETTING_ID[segment],
      key: QUIZ_UI_SETTING_ID[segment],
      value: uuid(),
    });
  }, [segment, updateUiSetting]);

  useEffect(() => {
    if (uiSettingStatus === QueryStatus.SUCCESS && uiSetting === null) {
      createQuizId();
    }
  }, [createQuizId, uiSetting, uiSettingStatus]);

  return [uiSetting?.value as QuizId, createQuizId];
};

export type { QuizId };
export { useQuizIdForSegment };
