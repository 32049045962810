import React, { FC } from "react";
import { View } from "react-native";
import { Button, BUTTON_VARIANT, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { Modal } from "@lookiero/sty-psp-ui";
import { I18nMessages } from "../../../../../../../i18n/i18n";
import { style } from "./ImagePickerModa.style";

interface ImagePickerModalProps {
  readonly visible: boolean;
  readonly disabled?: boolean;
  readonly onDismiss: () => void;
  readonly onTakePhoto: () => void;
  readonly onChooseFromLibrary: () => void;
}
const ImagePickerModal: FC<ImagePickerModalProps> = ({
  visible,
  disabled = false,
  onDismiss,
  onTakePhoto,
  onChooseFromLibrary,
}) => {
  const titleText = useI18nMessage({ id: I18nMessages.IMAGE_PICKER_MODAL_TITLE });
  const takePhotoButtonText = useI18nMessage({ id: I18nMessages.IMAGE_PICKER_MODAL_TAKE_PHOTO_BUTTON });
  const chooseFromLibraryButtonText = useI18nMessage({
    id: I18nMessages.IMAGE_PICKER_MODAL_CHOOSE_FROM_LIBRARY_BUTTON,
  });

  return (
    <Modal portalHostName="Quiz" visible={visible} showCloseButton onClose={onDismiss}>
      <View style={style.modal}>
        <Text level={1} action>
          {titleText}
        </Text>

        <View style={style.modalGap}>
          <Button
            disabled={disabled}
            level={3}
            style={[style.button, style.takePhotoButton]}
            variant={BUTTON_VARIANT.SECONDARY}
            upperCase
            wide
            onPress={onTakePhoto}
          >
            {takePhotoButtonText}
          </Button>
          <Button
            disabled={disabled}
            level={3}
            style={style.button}
            variant={BUTTON_VARIANT.SECONDARY}
            upperCase
            wide
            onPress={onChooseFromLibrary}
          >
            {chooseFromLibraryButtonText}
          </Button>
        </View>
      </View>
    </Modal>
  );
};

export { ImagePickerModal };
