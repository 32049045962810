import React, { FC, memo } from "react";
import { Navigate, Outlet, useRoutes as reactRouterUseRoutes } from "react-router-native";
import { Spinner } from "@lookiero/aurora";
import { I18n } from "@lookiero/i18n-react";
import { Kameleoon } from "@lookiero/sty-psp-ab-testing";
import { Country, Locale } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { KameleoonEnvironment } from "../../ab-testing/kameleoonEnvironment";
import { Layout } from "../../layout/Layout";
import { DefinitionProvider } from "../hooks/useDefinition";
import { StaticInfoProvider } from "../hooks/useStaticInfo";
import { App } from "../views/App";
import { NotFound } from "../views/notFound/NotFound";
import { Question } from "../views/question/Question";
import { SegmentQuestion } from "../views/segmentQuestion/SegmentQuestion";
import { QuizMiddleware } from "./QuizMiddleware";
import { SegmentMiddleware } from "./SegmentMiddleware";
import { Routes, Slugs } from "./routes";

interface RoutingProps {
  readonly basePath?: string;
  readonly locale: Locale;
  readonly country: Country;
  readonly segment: Segment;
  readonly kameleoon: KameleoonEnvironment;
  readonly I18n: I18n;
  readonly layout: Layout;
  readonly androidInAppBrowser: boolean;
  readonly apiUrl: () => string;
  readonly onBack?: () => void;
  readonly onI18nError?: (err: Error) => void;
  readonly useRoutes: typeof reactRouterUseRoutes;
}

const Routing: FC<RoutingProps> = ({
  basePath = "",
  locale,
  country,
  segment,
  kameleoon,
  I18n,
  layout,
  androidInAppBrowser,
  apiUrl,
  onBack,
  onI18nError,
  useRoutes = reactRouterUseRoutes,
}) => {
  return useRoutes([
    {
      path: "",
      element: (
        <StaticInfoProvider
          androidInAppBrowser={androidInAppBrowser}
          apiUrl={apiUrl}
          basePath={basePath}
          country={country}
          kameleoon={kameleoon}
          segment={segment}
        >
          <I18n loader={<Spinner />} locale={locale} onError={onI18nError}>
            <Kameleoon loader={<Spinner />} siteCode={kameleoon.siteCode}>
              <SegmentMiddleware>
                <App>
                  <Outlet />
                </App>
              </SegmentMiddleware>
            </Kameleoon>
          </I18n>
        </StaticInfoProvider>
      ),
      children: [
        {
          path: Routes.SEGMENT_QUESTION,
          element: <SegmentQuestion layout={layout} onBack={onBack} />,
        },
        {
          path: Routes.SEGMENT,
          element: (
            <DefinitionProvider>
              <QuizMiddleware>
                <Outlet />
              </QuizMiddleware>
            </DefinitionProvider>
          ),
          children: [
            {
              path: Slugs.QUESTION,
              element: <Question layout={layout} onBack={onBack} />,
            },
          ],
        },
        {
          path: Routes.NOT_FOUND,
          element: <NotFound layout={layout} />,
        },
        {
          path: "*",
          element: <Navigate to={`${Routes.HOME}`} replace />,
        },
      ],
    },
  ]);
};

/**
 * Provided useRoutes is not stable (when integrated with Sentry) as it's rendering a different component tree.
 *
 * https://github.com/getsentry/sentry-javascript/blob/master/packages/react/src/reactrouterv6.tsx#L221
 * (SentryRoutes is a new component after each re-render)
 */
const MemoizedRouting = memo(Routing);
export { MemoizedRouting as Routing };
