import React, { FC, useCallback, useEffect, useRef } from "react";
import { ScrollView } from "react-native";
import { Navigate, generatePath, useLocation, useNavigate, useParams } from "react-router-native";
import { Spinner } from "@lookiero/aurora";
import {
  questionIsHostScreen,
  prevQuestionSlug,
  prevQuestionPosition,
  useAnswers,
  AnswersValidationProvider,
  ScreenProjection,
  FocusedInputProvider,
} from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { MESSAGING_CONTEXT_ID } from "../../../../../delivery/baseBootstrap";
import { Layout } from "../../../../../layout/Layout";
import { useTrackQuestionModalPageView } from "../../../../../tracking/useTrackQuestionModalPageView";
import { useTrackQuestionPageView } from "../../../../../tracking/useTrackQuestionPageView";
import { HostScreenStepper } from "../../../../components/organisms/question/components/hostScreenStepper/HostScreenStepper";
import { QuestionHeader } from "../../../../components/templates/header/questionHeader/QuestionHeader";
import { useStaticInfo } from "../../../../hooks/useStaticInfo";
import { useViewQuizForSegment } from "../../../../hooks/useViewQuizForSegment";
import { LocationState } from "../../../../routing/LocationState";
import { useSegmentMiddleware } from "../../../../routing/SegmentMiddleware";
import { Routes } from "../../../../routing/routes";
import { QuestionForm } from "../questionForm/QuestionForm";

interface Location {
  readonly state: LocationState | null;
}

interface QuestionProps {
  readonly layout: Layout;
  readonly onBack?: () => void;
}
const QuestionLayout: FC<QuestionProps> = ({ layout: Layout, onBack }) => {
  const { state }: Location = useLocation();
  const navigate = useNavigate();
  const { slug } = useParams();
  const { basePath, country, segment, androidInAppBrowser } = useStaticInfo();
  const { requiresSegmentQuestion } = useSegmentMiddleware();

  const { id } = useViewQuizForSegment({ segment });
  const { questionsFilteredByAnswers } = useAnswers();

  const question = (questionsFilteredByAnswers?.filter<ScreenProjection>(questionIsHostScreen) || []).find(
    (question) => question.metadata.slug === slug,
  );

  const scrollRef = useRef<ScrollView>(null);
  useEffect(() => {
    scrollRef.current?.scrollTo({ y: 0, animated: false });
  }, [scrollRef, slug]);

  useTrackQuestionPageView({
    country,
    segment,
    slug: question?.metadata.slug,
    quizId: id,
  });
  const trackSizeGuideModalPageView = useTrackQuestionModalPageView({
    country,
    segment,
    slug: "size",
    quizId: id,
  });
  const trackSilhouetteGuideModalPageView = useTrackQuestionModalPageView({
    country,
    segment,
    slug: "silhouette",
    quizId: id,
  });

  const handleOnBack = useCallback(() => {
    if (!question || !questionsFilteredByAnswers) {
      return;
    }

    const prevSlug = prevQuestionSlug({ question, questions: questionsFilteredByAnswers });
    const prevPosition = prevQuestionPosition({ question, questions: questionsFilteredByAnswers });

    if (!prevSlug && requiresSegmentQuestion()) {
      navigate(generatePath(`${basePath}/${Routes.SEGMENT_QUESTION}`), {
        state: { ...state, backFromQuiz: true },
      });
      return;
    }

    if (prevPosition === 0 && state?.usf?.skipFirstQuestion) {
      onBack?.();
      return;
    }

    if (!prevSlug) {
      onBack?.();
      return;
    }

    navigate(generatePath(`${basePath}/${Routes.QUESTION}`, { segment: segment.toLowerCase(), slug: prevSlug }), {
      state,
    });
  }, [question, questionsFilteredByAnswers, requiresSegmentQuestion, state, onBack, navigate, basePath, segment]);

  if (!questionsFilteredByAnswers) {
    return <Spinner />;
  }

  if (!question) {
    return <Navigate to={generatePath(`${basePath}/${Routes.NOT_FOUND}`)} replace />;
  }

  return (
    <Layout
      header={<QuestionHeader onBack={handleOnBack} />}
      scrollRef={scrollRef}
      stepper={<HostScreenStepper active={question.metadata.groupTranslationKey} />}
    >
      <AnswersValidationProvider contextId={MESSAGING_CONTEXT_ID} scrollRef={scrollRef}>
        <FocusedInputProvider enabled={androidInAppBrowser} scrollRef={scrollRef}>
          <QuestionForm
            question={question}
            onOpenSilhouetteGuide={trackSilhouetteGuideModalPageView}
            onOpenSizeGuide={trackSizeGuideModalPageView}
          />
        </FocusedInputProvider>
      </AnswersValidationProvider>
    </Layout>
  );
};

export type { Location };
export { QuestionLayout };
