enum Slugs {
  QUESTION = ":slug",
}

enum Routes {
  HOME = "",
  SEGMENT_QUESTION = "segment",
  SEGMENT = ":segment",
  QUESTION = `:segment/${Slugs.QUESTION}`,
  NOT_FOUND = "not-found",
}

export { Routes, Slugs };
