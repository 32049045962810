import React, { FC } from "react";
import { View } from "react-native";
import { AspectRatioView, LazyImage } from "@lookiero/sty-psp-ui";
import { style } from "./SegmentQuestionImage.style";

interface SegmentQuestionImageProps {
  readonly src: string;
  readonly hiResSrc: string;
}

const SegmentQuestionImage: FC<SegmentQuestionImageProps> = ({ src, hiResSrc }) => (
  <View style={style.imageContainer}>
    <AspectRatioView aspectRatio={1.25}>
      <LazyImage
        hiResSrc={hiResSrc}
        resizeMode="contain"
        src={src}
        style={{ view: style.image }}
        testID="segment-question-image"
      />
    </AspectRatioView>
  </View>
);

export { SegmentQuestionImage };
