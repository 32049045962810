import { DomainEvent, MessageName } from "@lookiero/messaging";
import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { isQuizCompleted } from "../../../../domain/quiz/model/quizCompleted";
import { isQuizFilled } from "../../../../domain/quiz/model/quizFilled";
import { ViewQuizByIdResult, viewQuizById } from "../../../../projection/quiz/viewQuizById";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";

interface UseViewQuizByIdFunctionArgs {
  readonly id: string | undefined;
}

interface UseViewQuizByIdFunction {
  (args: UseViewQuizByIdFunctionArgs): UseQueryFunctionResult<ViewQuizByIdResult>;
}

const shouldInvalidate = (event: DomainEvent<MessageName>) => isQuizFilled(event) || isQuizCompleted(event);

const useViewQuizById: UseViewQuizByIdFunction = ({ id }) =>
  useQuery<ViewQuizByIdResult>({
    query: viewQuizById({ id: id as string }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: shouldInvalidate,
    options: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(id),
      keepPreviousData: true,
    },
  });

export { useViewQuizById };
