import React, { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from "react";
import invariant from "tiny-invariant";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { KameleoonEnvironment } from "../../ab-testing/kameleoonEnvironment";

interface SelectSegmentFunctionArgs {
  readonly segment: Segment;
}

interface SelectSegmentFunction {
  (args: SelectSegmentFunctionArgs): void;
}

interface StaticInfo {
  readonly apiUrl: () => string;
  readonly basePath: string;
  readonly country: Country;
  readonly kameleoon: KameleoonEnvironment;
  readonly androidInAppBrowser: boolean;
  readonly segment: Segment;
  readonly selectSegment: SelectSegmentFunction;
}

const StaticInfoContext = createContext<StaticInfo>(null as unknown as StaticInfo);

interface StaticInfoProviderProps {
  readonly children: ReactNode;
  readonly apiUrl: () => string;
  readonly basePath: string;
  readonly country: Country;
  readonly segment: Segment;
  readonly kameleoon: KameleoonEnvironment;
  readonly androidInAppBrowser?: boolean;
}

const StaticInfoProvider: FC<StaticInfoProviderProps> = ({
  apiUrl,
  basePath,
  country,
  segment: initialSegment,
  kameleoon,
  children,
  androidInAppBrowser = false,
}) => {
  const [segment, setSegment] = useState(initialSegment);
  const selectSegment: SelectSegmentFunction = useCallback(({ segment }) => setSegment(segment), []);

  const value = useMemo(
    () => ({ apiUrl, basePath, country, segment, kameleoon, androidInAppBrowser, selectSegment }),
    [androidInAppBrowser, apiUrl, basePath, country, kameleoon, segment, selectSegment],
  );

  return <StaticInfoContext.Provider value={value}>{children}</StaticInfoContext.Provider>;
};

const useStaticInfo = () => {
  const staticInfo = useContext(StaticInfoContext);

  invariant(
    staticInfo,
    "Your are trying to use the useStaticInfo hook without wrapping your app with the <StaticInfoProvider>.",
  );

  return staticInfo;
};

export type { SelectSegmentFunction };
export { StaticInfoProvider, useStaticInfo };
