import React, { FC, useCallback, useEffect, useRef } from "react";
import { ScrollView, View } from "react-native";
import { generatePath, useNavigate } from "react-router-native";
import { Button, Text } from "@lookiero/aurora";
import { Segment } from "@lookiero/sty-psp-segment";
import { Layout } from "../../../layout/Layout";
import { TrackingPage } from "../../../tracking/tracking";
import { useTrackPageView } from "../../../tracking/useTrackPageView";
import { useTrackSegmentConfirmation } from "../../../tracking/useTrackSegmentConfirmation";
import { QuestionHeader } from "../../components/templates/header/questionHeader/QuestionHeader";
import { useStaticInfo } from "../../hooks/useStaticInfo";
import { Routes } from "../../routing/routes";
import { style } from "./SegmentQuestion.style";
import { SegmentQuestionImage } from "./components/SegmentQuestionImage";

interface SegmentQuestionProps {
  readonly layout: Layout;
  readonly onBack?: () => void;
}

const SegmentQuestion: FC<SegmentQuestionProps> = ({ layout: Layout, onBack }) => {
  const scrollRef = useRef<ScrollView>(null);
  useEffect(() => {
    scrollRef.current?.scrollTo({ y: 0, animated: false });
  }, [scrollRef]);

  const { basePath, country } = useStaticInfo();
  const navigate = useNavigate();
  const trackSegmentConfirmation = useTrackSegmentConfirmation({
    country,
    page: TrackingPage.SEGMENT_CONFIRMATION,
  });

  useTrackPageView({
    country,
    segment: "" as Segment, // There is no segment at this point
    page: TrackingPage.SEGMENT_CONFIRMATION,
    quizId: "",
  });

  const onSegmentSelected = useCallback(
    async (segment: Segment) => {
      trackSegmentConfirmation({ segment });
      navigate(generatePath(`${basePath}/${Routes.SEGMENT}`, { segment: segment.toLowerCase() }));
    },
    [basePath, navigate, trackSegmentConfirmation],
  );

  const handleOnWomenSelected = useCallback(() => onSegmentSelected(Segment.WOMEN), [onSegmentSelected]);
  const handleOnMenSelected = useCallback(() => onSegmentSelected(Segment.MEN), [onSegmentSelected]);

  return (
    <Layout header={<QuestionHeader onBack={onBack} />} scrollRef={scrollRef} stepper={<></>}>
      <View style={style.photos}>
        <View style={style.photoColumn}>
          <SegmentQuestionImage hiResSrc={"https://picsum.photos/200/252"} src={"https://picsum.photos/100/126"} />
          <SegmentQuestionImage hiResSrc={"https://picsum.photos/200/252"} src={"https://picsum.photos/100/126"} />
        </View>
        <View style={style.photoColumn}>
          <SegmentQuestionImage hiResSrc={"https://picsum.photos/200/252"} src={"https://picsum.photos/100/126"} />
          <SegmentQuestionImage hiResSrc={"https://picsum.photos/200/252"} src={"https://picsum.photos/100/126"} />
          <SegmentQuestionImage hiResSrc={"https://picsum.photos/200/252"} src={"https://picsum.photos/100/126"} />
        </View>
        <View style={style.photoColumn}>
          <SegmentQuestionImage hiResSrc={"https://picsum.photos/200/252"} src={"https://picsum.photos/100/126"} />
          <SegmentQuestionImage hiResSrc={"https://picsum.photos/200/252"} src={"https://picsum.photos/100/126"} />
        </View>
      </View>
      <Text level={3} style={style.title} heading>
        ¿Para quien creamos estilo?
      </Text>
      <View style={style.ctaContainer}>
        <Button style={style.cta} onPress={handleOnWomenSelected}>
          Mujer
        </Button>
        <Button style={style.cta} onPress={handleOnMenSelected}>
          Hombre
        </Button>
      </View>
    </Layout>
  );
};

export { SegmentQuestion };
