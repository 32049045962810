import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, SegmentConfirmationTrackingEvent, TrackingEventName, TrackingPage } from "./tracking";

interface TrackSegmentConfirmationFunctionArgs {
  readonly segment: Segment;
}

interface TrackSegmentConfirmationFunction {
  (args: TrackSegmentConfirmationFunctionArgs): void;
}

interface UseTrackSegmentConfirmationFunctionArgs {
  readonly page: TrackingPage;
  readonly country: Country;
}

interface UseTrackSegmentConfirmationFunction {
  (agrs: UseTrackSegmentConfirmationFunctionArgs): TrackSegmentConfirmationFunction;
}

const useTrackSegmentConfirmation: UseTrackSegmentConfirmationFunction = ({ page, country }) => {
  const emitUserEvent = useEmitUserEvent<SegmentConfirmationTrackingEvent>();

  const trackSegmentConfirmation: TrackSegmentConfirmationFunction = useCallback(
    ({ segment }) => {
      emitUserEvent({
        event: TrackingEventName.SEGMENT_CONFIRMATION,
        eventCategory: TrackingEventCategory.NAVIGATION,
        section: `${PROJECT}_${page}`,
        store: country,
        segment,
      });
    },
    [country, emitUserEvent, page],
  );

  return trackSegmentConfirmation;
};

export { useTrackSegmentConfirmation };
